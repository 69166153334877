/* ensure all pages have Bootstrap CSS */
@import './variables';

$background-color: #f9fafc;

.app-component {
    background: $background-color;
    min-height: 100vh;
}

body {
    margin: 0;
    font-family: 'Montserrat', sans-serif !important;
}

// @keyframes moveFirst {
//     0% {
//         transform: translate(0, 0);
//     }
//     16.66% {
//         transform: translate(10px, 10px);
//     }
//     49.98% {
//         transform: translate(10px, 10px);
//     }
//     66.64% {
//         transform: translate(0, 0);
//     }
//     100% {
//         transform: translate(0, 0);
//     }
// }
// @keyframes moveSecond {
//     0% {
//         transform: translate(0, 0);
//     }
//     16.66% {
//         transform: translate(-16px, 10px);
//     }
//     49.98% {
//         transform: translate(-16px, 10px);
//     }
//     80% {
//         transform: translate(0, 0);
//     }
//     100% {
//         transform: translate(0, 0);
//     }
// }
// @keyframes moveThird {
//     0% {
//         transform: translate(0, 0);
//     }
//     16.66% {
//         transform: translate(0, -14px);
//     }
//     49.98% {
//         transform: translate(0, -14px);
//     }
//     66.64% {
//         transform: translate(0, 0);
//     }
//     100% {
//         transform: translate(0, 0);
//     }
// }
// .firstPart {
//     position: absolute;
//     top: -10px;
//     left: -10px;
//     animation: moveFirst 3s linear infinite;
// }
// .secondPart {
//     position: absolute;
//     top: -10px;
//     right: -10px;
//     animation: moveSecond 3s linear infinite;
//     animation-delay: 0.5s;
// }
// .thirdPart {
//     position: absolute;
//     bottom: -10px;
//     left: 0;
//     animation: moveThird 3s linear infinite;
//     animation-delay: 1s;
// }

.text-idy-default {
    color: $idy-color-default !important;
}
.text-idy-primary {
    font-weight: normal;
    color: $idy-color-primary !important;
}
.text-idy-info {
    color: $idy-color-info !important;
    padding: 0 20px 0 12px;
    cursor: default;
}

.link-idy-info {
    color: #5a567c !important;
    text-decoration: underline;
    &:hover {
        color: #2b1f86 !important;
        text-decoration: none;
    }
}

.pointer {
    cursor: pointer;
}

.drag-and-drop-container {
    width: 300px;
    height: 300px;
    border: 2px dashed $idy-color-progress-secondary;
    border-radius: 2rem;
    &:focus {
        outline: none;
        background-color: rgba(148, 148, 211, 0.1);
    }
}

.btn-idy-primary {
    color: #fff;
    background-color: $idy-btn-color-default !important;
    font-style: normal;
    letter-spacing: 1.09px;

    &:hover {
        color: rgb(224, 224, 224) !important;
        box-shadow: none !important;
        filter: drop-shadow(0 3px 15px rgba(109, 99, 255, 0.1)) !important;
    }
}

.btn-rounded {
    border-radius: 9000px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

/*here should be mobile exclusive css values*/
@media only screen and (min-width: 5rem) {
    .idy-floating-logo {
        background: url('../style/icons/logo.svg') no-repeat center center;
        background-size: contain;
        width: 29px;
        height: 32px;
        margin: 0;
    }
    .idy-floating-logo-white {
        background: url('../style/icons/logo-white.svg') no-repeat center center;
        background-size: contain;
        width: 29px;
        height: 32px;
        margin: 0;
    }
    .idy-title-wrapped {
        margin-left: auto !important;
        margin-right: auto !important;
        max-width: 250px;
        font-size: 1.5rem;
        line-height: 1.2;
        cursor: default;
    }
    .il {
        width: 100%;
        height: 160px;
        background-size: contain !important;

        &.il-robot {
            background: url('../style/img/ilustrations/il-robot.png') center center no-repeat;
        }
        &.il-mirror {
            background: url('../style/img/ilustrations/il-mirror.png') center center no-repeat;
        }
        &.il-phone {
            background: url('../style/img/ilustrations/il-phone.png') center center no-repeat;
            background-position: 57%;
        }
        &.il-phone-code {
            background: url('../style/img/ilustrations/il-phone-code.png') center center no-repeat;
            background-position: 57%;
        }
        &.il-robot-interactive {
            background: url('../style/img/ilustrations/il-robot-interactive.png') center center no-repeat;
        }
        &.il-human-interaction {
            background: url('../style/img/ilustrations/il-human-interaction.png') center center no-repeat;
        }
        &.il-hologram {
            background: url('../style/img/ilustrations/il-hologram.png') center center no-repeat;
        }
        &.il-shield {
            background: url('../style/img/ilustrations/il-shield.png') center center no-repeat;
        }
    }
}

.error-text {
    color: red;
}

.fontS-1 {
    font-size: 0.885rem;
}

.dropzone {
    border: none;
    outline: none;
    &:focus {
        outline: none;
    }
}

/*here should be tablet exclusive css values*/
@media only screen and (min-width: 42rem) {
    .idy-floating-logo {
        width: 68px;
        height: 58px;
    }
    .idy-floating-logo-white {
        width: 68px;
        height: 58px;
    }
    .idy-title-wrapped {
        max-width: 350px;
        font-size: 1.75rem;
    }
    .il {
        height: 200px;
    }
}

/*here should be desktop exclusive css values*/
@media only screen and (min-width: 81.25rem) {
}

.e-mail {
    .MuiInputBase-root {
        .MuiInputBase-input {
            text-transform: lowercase;
        }
    }
}
