.root {
    width: 100%;
    height: 100vh;
    padding-bottom: 3rem;
}
.ilustration {
    position: fixed;
    width: 100%;
    height: 60vh;
    opacity: 20%;
    top: 25%;
    left: 0px;
    right: 0px;
    bottom: 0px;
}
