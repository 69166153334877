.mainDiv {
    width: 100%;
    height: 100vh;
    padding: 3rem;
}
.error {
    font-size: 1.25rem;
    color: red;
}
.retryBtn {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-width: 3px;
    z-index: 100;
}
