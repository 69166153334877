.root {
    display: flex;
    width: 240px;
}
.appBar {
    width: calc(100% - 240px);
    margin-left: 240px;
}
.drawer {
    width: 240px;
    flex-shrink: 0;
}
.drawerPaper {
    width: 240px;
    top: 64px !important;
}
.navList {
    padding-top: 2px;
    padding-bottom: 2px;
    background: white;
}
.navLink {
    text-decoration: none;
    display: block;
    flex-grow: 1;
    margin-top: 1px;
    margin-bottom: 1px;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 2px;
    padding-right: 2px;
    &:hover {
        color: rgba(0, 0, 0, 0.6);
        text-decoration: none;
        background-color: rgba(0, 0, 0, 0.04);
    }
    &.active {
        background-color: #3f51b5;
        color: #fff !important;
    }
    &:active {
        color: rgba(0, 0, 0, 0.8) !important;
    }
    &.active:hover {
        color: #ccc !important;
    }
    &:visited:not(.active):not(:hover) {
        color: #444;
    }
}
.userName {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.acitve {
    background-color: #444;
}
