.mainContainer {
    padding: 2rem;
}
.inputMargin {
    margin: 1rem 0 !important;
}
.buttonGroup {
    width: fit-content;
    margin: 1rem 0;
    align-self: center;
}

.root {
    flex-grow: 1;
    overflow: hidden;
}
.flexGrow {
    flex-grow: 1;
}
.userName {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.mainContainer {
    padding: 2rem;
}
.titles {
    @media (min-width: 300px) {
        display: none;
    }
    @media (min-width: 481px) {
        display: block;
    }
}

.filter {
    width: 40%;
}
