.videoComponent {
    width: 100%;
    height: auto;
}
.videoCompMirrored {
    width: 100%;
    height: auto;
    transform: scaleX(-1);
}

.root {
    flex-grow: 1;
}
.toolbar {
    padding-left: 0;
}
.flexGrow {
    flex-grow: 1;
}
.userName {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.mainContainer {
    padding: 2rem;
}
.inputMargin {
    margin: 1rem 0 !important;
}
.buttonGroup {
    width: fit-content;
    margin: 2rem 0;
    align-self: center;
}
.videoComp {
    width: 100%;
    @media (min-width: 65rem) {
        align-self: center;
        width: 40%;
    }
    @media (min-width: 88rem) {
        align-self: center;
        width: 30%;
    }
}
.errorComp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 15rem;
    @media (min-width: 65rem) {
        align-self: center;
        width: 40%;
    }
    @media (min-width: 88rem) {
        align-self: center;
        width: 30%;
    }
}
.media {
    height: 0px;
    padding-top: 56.25%;
    margin: 0.5rem 0;
}
.instructions {
    text-align: center;
    margin-top: 1rem;
}
.sideInstructions {
    display: flex;
    text-align: center;
    justify-content: center;
    margin-bottom: 0.5rem;
}

.instructions {
    text-align: center;
    margin-top: 1rem;
}

.mainContainerComp {
    width: 100%;
    padding: 2rem;
}
.alternativeLabel {
    @media (min-width: 300px) {
        display: none;
    }
    @media (min-width: 481px) {
        display: block;
    }
}

.inputMarginComp {
    margin: 1rem 0 !important;
    align-self: center;
    width: 50%;
}
.buttonGroup {
    width: fit-content;
    margin: 1rem 0;
    align-self: center;
}

.buttonGroup {
    width: fit-content;
    margin: 2rem 0;
    align-self: center;
}
.media {
    height: 0px;
    padding-top: 56.25%;
    margin: 0.5rem 0;
}
.instructions {
    text-align: center;
    margin-top: 1rem;
}
.error-text {
    color: #f44336;
    margin-left: 14px;
}
