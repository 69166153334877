.root {
    flex-grow: 1;
    overflow: hidden;
}
.flex-grow {
    flex-grow: 1;
}
.userName {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.mainContainer {
    padding: 2rem;
}
.titles {
    @media (min-width: 300px) {
        display: none;
    }
    @media (min-width: 481px) {
        display: block;
    }
}
