.root {
    flex-grow: 1;
    overflow: hidden;
}
.toolbar {
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.flexGrow {
    flex-grow: 1;
}
.userName {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.mainContainer {
    padding: 2rem;
}
.filter {
    width: 40%;
    margin: 1rem !important;
}
.titles {
    @media (min-width: 300px) {
        display: none;
    }
    @media (min-width: 481px) {
        display: block;
    }
}
