.root {
    flex-grow: 1;
}
.toolbar {
    padding-left: 0;
}
.flexGrow {
    flex-grow: 1;
}
.userName {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.mainContainer {
    padding: 2rem;
}
.inputMargin {
    margin: 1rem 0 !important;
}
.buttonGroup {
    width: fit-content;
    margin: 1rem 0;
    align-self: center;
}
.passwordForm {
    max-width: 500px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.uploadBtn {
    position: relative;
}
.buttonProgress {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
}
.titles {
    @media (min-width: 300px) {
        display: none;
    }
    @media (min-width: 481px) {
        display: block;
    }
}
